<!-- 课程 -->
<template>
  <page-container
      ref="pageContainer"
      class="course-detail-container"
      :class="{ isVideo }"
  >
    <video
        v-if="isVideo"
        :src="courseData.content"
        controls
    />
    <div
        v-if="isRich"
        v-html="courseData.content"
        class="rich-content"
    />

    <div class="course-detail-bottom-bar">
      <van-button type="primary" block round @click="handleToNextPage">去答题</van-button>
    </div>
  </page-container>

</template>

<script>
import { COURSE_CONTENT_TYPE } from './const';
import flowChain from '@/util/flowChain';
import { finishCourse, getCourseInfo } from '@/api/course';
import { Dialog } from 'vant'; // 获取用户当前所需要进行任务流程

export default {
  name: 'courseDetail',
  data() {
    return {
      courseData: {},
    };
  },
  computed: {
    isRich() {
      return this.courseData.type === COURSE_CONTENT_TYPE.RICH;
    },
    isVideo() {
      return this.courseData.type === COURSE_CONTENT_TYPE.VIDEO;
    },
  },
  created() {
    let courseId = this.$route.query.courseId;
    getCourseInfo({ courseId })
        .then(res => {
          this.courseData = res || {};
        });
  },
  methods: {
    handleToNextPage() {
      Dialog.confirm({
        title: `是否去答题？`,
        // message: '弹窗内容',
        confirmButtonText: '确认',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            let { courseId, isFlowChain } = this.$route.query;
            finishCourse({ courseId }).then(res => {
              // 获取用户当前所需要进行任务流程
              if (isFlowChain) {
                flowChain('evalId').then(location => {
                  if (location)
                    this.$router.replace(location);
                  else
                    this.$router.replace('/home');
                });
              } else {
                this.$router.replace({
                  path: '/subjectDetail',
                  query: { courseId },
                })
              }
              done();
            });
          } else {
            done();
          }
        }
      }).catch(() => {
      });
    },
  }
};
</script>

<style lang="less" scoped>
.course-detail-container {
  padding: 0 0 54px 0;

  /deep/ img {
    max-width: 100%;
    height: auto;
  }

  /deep/ video {
    width: 100%;
  }

  .course-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    width: 100%;
    height: 54px;
    background-color: #fff;

    .van-button {
      margin: 0 10px;
      padding: 0 12px;
      font-size: 16px;
    }
  }

  &.isVideo {
    display: flex;
    align-items: center;
    background-color: #000;
    background: -webkit-linear-gradient(to bottom, #000000 8%, #3d3d3d);
    background: linear-gradient(to bottom, #000000 8%, #3d3d3d);

    .course-detail-bottom-bar {
      background-color: #0000;
    }
  }
}
</style>
